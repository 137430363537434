ion-item-sliding.track-ionic ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}
ion-item-sliding.track-angular ion-label {
  border-left: 2px solid var(--ion-color-angular);
  padding-left: 10px;
}
ion-item-sliding.track-communication ion-label {
  border-left: 2px solid var(--ion-color-communication);
  padding-left: 10px;
}
ion-item-sliding.track-tooling ion-label {
  border-left: 2px solid var(--ion-color-tooling);
  padding-left: 10px;
}
ion-item-sliding.track-services ion-label {
  border-left: 2px solid var(--ion-color-services);
  padding-left: 10px;
}
ion-item-sliding.track-design ion-label {
  border-left: 2px solid var(--ion-color-design);
  padding-left: 10px;
}
ion-item-sliding.track-workshop ion-label {
  border-left: 2px solid var(--ion-color-workshop);
  padding-left: 10px;
}
ion-item-sliding.track-food ion-label {
  border-left: 2px solid var(--ion-color-food);
  padding-left: 10px;
}
ion-item-sliding.track-documentation ion-label {
  border-left: 2px solid var(--ion-color-documentation);
  padding-left: 10px;
}
ion-item-sliding.track-navigation ion-label {
  border-left: 2px solid var(--ion-color-navigation);
  padding-left: 10px;
}

ion-item-divider {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  background: white !important;
  color: var(--ion-color-primary);
  padding-top: 5px;
  padding-left: 25px;
  font-weight: bold;
}

ion-list {
  background: transparent !important;
}

.agenda-item ion-label h3 {
  font-weight: bold;
}

.agenda-item ion-note {
  font-size: 14px;
  font-weight: bold;
  text-align: right !important;
  width: 70px;
}

.day-label p {
  color: var(--ion-color-primary) !important;
}
