.attendee-list ion-item {
  --inner-padding-start: 10px;
}

.attendee-list p {
  padding-bottom: 1px !important;
}

.attendee-profile-small ion-avatar {
  height: 36px;
  width: 100%;
  text-align: center;
  position: relative;
}

.attendee-profile-small img {
  width: 36px;
  height: 100%;
  margin-right: 15px;
}

