.login-container {
  height: 100vh;
}

.login-container .info-container {
  height: calc(100vh - 210px);
}

.info-container ion-slides {
  height: 100%;
}

.login-button-container {
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--visitops-color-login-background);
}

.login-button-container div {
  margin: 0 auto;
}


.swiper-pagination-bullet {
  opacity: 0.5 !important;
  background: #5F5F5F !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background: #FFF !important;
}
