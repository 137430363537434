.profile-image ion-avatar {
  height: 200px;
  width: 100%;
  text-align: center;
  position: relative;
}

.profile-image img {
  width: 200px;
}

.profile-image .profile-image-input {
  position: absolute;
  top: 0px;
  right: 100px;
}

.profile-image .profile-image-input > * {
  position: absolute;
}

.profile-image .hidden {
  opacity: 0%;
  z-index: 2;
}

.profile-image ion-icon {
  height: 20px;
  width: 20px;
}

.profile-info {
  margin-top: 20px;
}