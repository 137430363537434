/* Custom css for app */

ion-toolbar {
  padding-left: 13px;
  padding-right: 13px;
}

ion-tab-bar {
  height: 72px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
}

.subheader-padding {
  padding-left: 25px;
  padding-right: 25px;
  background: var(--ion-color-light);
}

.subheader-padding h2 {
  margin-bottom: 14px;
  /* min-height: 56px; */
}

.subheader-padding .dates,
.subheader-padding .location {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.subheader-padding .dates {
  color: var(--ion-color-primary);
}

.subheader-padding .location {
  color: var(--ion-color-medium);
}

.subheader-padding ion-grid {
  padding: 0;
}

.subheader-padding ion-col {
  padding: 0;
}

.subheader-padding ion-buttons {
  justify-content: flex-end !important;
  margin-right: -10px;
}

.list {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

.top-round {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  background: white !important;
}

.slide-menu ion-list {
  margin-bottom: 1px !important;
  border-bottom: 1px solid var(--ion-color-light);
}

.slide-menu ion-list.last {
  margin-bottom: 0 !important;
  border-bottom: none !important;
}

.slide-menu ion-item-divider {
  padding-left: 15px !important;
  color: var(--ion-color-dark) !important;
}

.slide-menu {
  background: white !important;
}

.menu-modal-content {
  --padding-start: 16px !important;
  --padding-end: 16px !important;
}

.speaker-chip {
  border-radius: 3px;
  padding-left:  5px;
  padding-right: 5px;
  margin-right: 3px;
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
}

ion-refresher {
  background: var(--ion-color-light);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.p-3 {
  padding: 15px;
}

.header-ios ion-toolbar:last-child {
  --border-width: 0 !important;
}

ion-header {
  background: var(--ion-color-light);
}

.not-found-list {
  text-align: center;
  color: #7e7e7e;
  padding: 25px;
}

.not-found-list h2 {
  margin-top: 24px;
  margin-bottom: 17px;
  font-size: 24px;
  font-weight: bold;
  height: 27px;
}

.not-found-list p {
  font-size: 17px;
}

.not-found-list ion-icon {
  font-size: 56px;
}

/* My Visits back button */
ion-button a {
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -10px;
}

ion-refresher {
  background: transparent;
}
