.agenda-item-content {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
}

.agenda-item-content h3 {
  font-size: 14px;
  font-weight: bold;
  color: #444444;
}

.agenda-item-content p {
  color: #7E7E7E;
}

.agenda-session .title {
  padding-bottom: 1px;
}

.agenda-list {
  padding-bottom: 20px;
}

.agenda-list ion-item {
  --min-height: 80px;
}

.presenter-label {
  margin-left: 20px;
}

.small-image ion-avatar {
  height: 36px;
  width: 100%;
  margin-left: 7px;
  text-align: center;
  position: relative;
}

.small-image img {
  width: 36px;
  height: 100%;
}
