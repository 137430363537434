.mobile-app-download {
  padding: 5%;
}

.mobile-app-download h2,
.mobile-app-download p {
  text-align: center;
}

.mobile-app-download h2 {
  margin-bottom: 40px;
}

.mobile-app-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.mobile-app-links a {
  display: block;
  width: 100%;
  max-width: 260px;
}

.mobile-app-links a:first-child {
  padding: 16px;
}

.mobile-app-links img {
  width: 100%;
  height: auto;
}
