.presenter-header {
  margin-top: 18px;
}

.presenter-right {
  padding-left: 15px !important;
}

.presenter-header h2 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.presenter-header p {
  line-height: 20px;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.presenter-title {
  font-weight: bold;
}

.presenter-header .speaker-chip-container {
  font-size: 12px;
}

.agenda-item-content {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
}

.agenda-item-content h3 {
  font-size: 14px;
  font-weight: bold;
  color: #444444;
}

.agenda-item-content p {
  color: #7E7E7E;
}


.page-image ion-avatar {
  height: 100px;
  width: 100%;
  text-align: center;
  position: relative;
}

.page-image img {
  width: 100px;
  height: 100%;
}
