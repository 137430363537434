.details-content {
  padding-top: 1px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.details-content .page-title {
  margin-top: 14px;
  font-size: 17px;
  color: var(--ion-color-primary);
  font-weight: 700;
}

.details-content .welcome {
  font-size: 20px;
  font-weight: bold;
}

.details-content .subheader {
  font-size: 16px;
  font-weight: bold;
}

.details-content p {
  font-size: 14px;
}

.details-content .img-container {
  margin-left: -25px;
  margin-right: -25px;
}

.details-content img {
  width: 100%;
  height: auto;
}
