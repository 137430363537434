.content-list ion-item {
  --min-height: 80px;
}

.content-preview {
  padding-bottom: 20px;
  background-color: white;
}

.content-preview img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

