ion-list-header {
 padding-left: 25px;
}

.loader {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.visit-item ion-note {
  width: 40px;
  margin-left: 5px;
  text-align: center;
}

h2.primary {
  margin-bottom: 24px;
}

.subheader-padding {
  margin-bottom: 19.5px;
}

ion-label h2 {
  font-weight: bold !important;
}

ion-item {
  --border-color: #ebebeb;
}

ion-label p {
  font-size: 12px !important;
  padding-top: 2.5px !important;
}
